// Stores
import { useSearchStore } from '~/store'

// Composables
import useSearch from '@/composables/search/useSearch'
import useFacets from '@/composables/search/useFacets'
import { useFeatureFlag } from '@/composables/featureFlags/useFeatureFlag'

// Common
import { sortMapping } from '@/common/mapping/mapping'
import { SEARCH_REDIRECT_PHRASES, RESULTS_PER_PAGE_NUMBERS, NESTED_FACET_NAMES } from '@/common/static/search'
import {
  showAllFacetsArray,
  showAllFacetsArrayFlat,
  nestedFacets,
  facetObjects,
  FACETNAMES,
} from '@/common/static/facets'
import { removeSpecialCharacters, decodeHtml } from '@/common/utils/characters'

// Types
import { SearchOptionsInterface } from '@/types/search/searchTypes'

export default () => {
  const newSearchUrlStructure = useFeatureFlag('newSearchUrlStructure')
  const searchStore = useSearchStore()
  const { setDefaultSearchSettings } = useSearch()
  const { addFacet, mapToFacetName } = useFacets()
  const SEARCH_URL_SEPARATOR = newSearchUrlStructure.value ? ',' : '|'

  /*
      Method to revert the search SEF value
      - all dashes back to space
      - the word and back to &
      - %2c back to ,
      
      RETURNS: string
    */
  const revertSearchSEFValue = (string: string) => {
    // If the new structure is enabled, make all lowercase and replace spaces with dashes
    return string.replace(/and/g, '&').replace(/-/g, ' ').replace(/nr/g, '#').toLowerCase()
  }

  /*
      Method to create a search SEF value
      - Remove all special characters
      - Make lowerCase and replace spaces with dashes
      - Replace & with and      
      
      RETURNS: string
    */
  const getSearchSEFValue = (value: string) => {
    // Replace # first to keep the sorting values
    const newValue = decodeHtml(value).replace(/#/g, 'nr')

    return (
      removeSpecialCharacters(newValue)
        // Support for & and space-&-space
        .replace(/ & /g, '-and-')
        .replace(/&/g, '-and-')
        // All spaces to dashes
        .replace(/\s+/g, '-')
        // If a value has a dash, we get 3 dashes. Replace them with 1
        .replace(/---/g, '-')
        // Everythinng lowercase
        .toLowerCase()
    )
  }

  /*
      Method to create a search SEF key
      - maps the facetname to a pretty name
      - makes lowercase
      
      RETURNS: string
    */
  const getSearchFacetSEFKey = (key: string) => {
    return getSearchSEFValue(mapToFacetName(key, FACETNAMES.PRETTY_FACET_NAME).toLowerCase())
  }

  /*
      Method to revert a search SEF key
      - maps the facetname to a slug name      
      
      RETURNS: string
    */
  const revertSearchFacetSEFKey = (key: string) => {
    return mapToFacetName(revertSearchSEFValue(key), FACETNAMES.SLUG_FACET_NAME)
  }

  /*
  Sort the facet values alphabetically
      
      RETURNS: Array<string>
    */
  const getSortedFacetKeys = () => {
    const sortedFacetKeysArray = searchStore.facets.checkedFacets.map((el: any) => Object.keys(el)[0]).sort()
    if (sortedFacetKeysArray.includes('categories')) {
      sortedFacetKeysArray.splice(sortedFacetKeysArray.indexOf('categories'), 1)
      sortedFacetKeysArray.unshift('categories')
    }
    return sortedFacetKeysArray
  }

  /*
  Get all checked facets 
  Add them to the urlParams
  - do this for regular and nested facets  
      
      RETURNS: URLSearchParams
    */
  const getFacetsForProductTab = (urlParams: URLSearchParams) => {
    const otherKeys: Array<string> = []
    const checkedFacets = searchStore.facets.checkedFacets

    // If resources, just return the params
    if (searchStore.isResourcesTab) return urlParams

    // If no facets are checked, return the urlParams
    if (Object.keys(checkedFacets).length === 0) return urlParams

    // Loop over all sorted checked facets
    getSortedFacetKeys().forEach((sortedFacetKey: string) => {
      // Add other facets
      if (nestedFacets.includes(sortedFacetKey)) {
        // Find nice name other facet
        const otherFacetPrettyName = mapToFacetName(sortedFacetKey, FACETNAMES.PRETTY_FACET_NAME).toLowerCase()
        if (otherFacetPrettyName.length > 0) {
          otherKeys.push(getSearchFacetSEFKey(otherFacetPrettyName))
        }
      }
      // Add regular facets
      else {
        const foundCheckedFacet = checkedFacets.find((el) => el[sortedFacetKey])

        if (foundCheckedFacet) {
          const joinedValue = foundCheckedFacet[sortedFacetKey]
            .map((el: string) => getSearchSEFValue(el))
            .sort()
            .join(SEARCH_URL_SEPARATOR)
          urlParams.append(getSearchFacetSEFKey(sortedFacetKey), joinedValue)
        }
      }
    })

    // Append possible other facet
    if (otherKeys.length > 0) {
      // Sort the other keys alphabetically
      const sortedOtherKeys = otherKeys.sort()
      urlParams.append('other', sortedOtherKeys.join(SEARCH_URL_SEPARATOR))
    }

    return urlParams
  }

  /*
      Method to define the search url, based on every search item in the store
      
      RETURNS: string
    */
  const defineSearchUrl = (queryoptions: SearchOptionsInterface) => {
    // Start new URL params
    let urlParams = new URLSearchParams()

    // Add the facets
    urlParams = getFacetsForProductTab(urlParams) as URLSearchParams

    // Add the other parameters
    urlParams = getOtherUrlParams(urlParams, queryoptions) as URLSearchParams

    return `/browse?${urlParams.toString()}`
  }

  const getOtherUrlParams = (urlParams: URLSearchParams, queryoptions: SearchOptionsInterface) => {
    // This next group is also order alphabetically
    // Disable scroll to top
    if (queryoptions.noscroll !== undefined) {
      urlParams.append('noscroll', '1')
    }

    // pagination
    if (queryoptions.nr !== undefined && queryoptions.nr !== 1) {
      urlParams.append('nr', queryoptions.nr.toString())
    }

    // Search phrase
    if (searchStore.searchInput.length > 0) {
      urlParams.append('q', encodeURIComponent(searchStore.searchInput))
    }

    // redirect, first check if we defined it
    if (queryoptions.redirect !== undefined) {
      urlParams.append('redirect', queryoptions.redirect.toString())
    } else if (process.client) {
      const redirectParam = new URL(window.location.href).searchParams.get('redirect')
      if (redirectParam !== null) {
        if (redirectParam === 'false') {
          urlParams.append('redirect', 'false')
        }
      }
    }

    // results per page
    if (searchStore.page.resultsPerPage !== 30) {
      urlParams.append('results-per-page', encodeURIComponent(searchStore.page.resultsPerPage.toString()))
    }

    // sortby, only add if it different then default
    if (searchStore.isProductTab && searchStore.page.sortField !== 'Relevance') {
      if (queryoptions.sortby !== undefined) {
        urlParams.append('sort-by', encodeURIComponent(getSearchSEFValue(queryoptions.sortby)))
      } else {
        urlParams.append('sort-by', encodeURIComponent(getSearchSEFValue(searchStore.page.sortField)))
      }
    }

    // set selected tab, only for the resources tab
    if (searchStore.isResourcesTab) {
      urlParams.append('tab', searchStore.tabs.selectedTab)
    }

    if (queryoptions.queryStyle) {
      urlParams.append('qs', queryoptions.queryStyle)
    }

    return urlParams
  }

  /*
    Get the current route from the browse page
    extract all query parameters
    if inserted, change them in the store
    after this check, perform a search

    Needs to be async because we use await on browse page. It needs to return a promise
    RETURNS: void
  */
  const setUrlParameters = (path: Record<string, string | (string | null)[]>) => {
    // skip if this is empty
    if (path === undefined || path == null || Object.keys(path).length === 0) return

    // if several path and duplicate paths with same property
    if (path.isArray && path[0] === path[1]) return path[0]

    // Back to default
    setDefaultSearchSettings()

    // get all path keys
    const pathkeys = Object.keys(path)

    // If no path keys, return
    if (pathkeys.length === 0) return

    // support legacy parameter structure
    // TODO: can be removed after complete content migration
    if (path.Ntt !== undefined && path.Ntt !== '') {
      searchStore.searchInput = decodeURIComponent(path.Ntt.toString())
    }

    // loop over the URL parameters
    pathkeys.forEach((key) => {
      const value = path[key]

      // If we have no value for this key, continue with the next loop
      if (!value) return

      // Get the value of the key
      const urlValue: string | null = Array.isArray(value) ? value[0] : value

      // If we have no value for this key, continue with the next loop
      if (!urlValue) return

      // Handle facets
      setFacets(key, urlValue)

      // Set all other URL parameters
      setOtherParameters(key, urlValue)

      // Check for search phrase redirects
      setSearchPhraseRedirect()
    })
  }
  /*
    Set search settings based on URL parameters

    RETURNS: void
  */
  const setOtherParameters = (key: string, urlValue: string) => {
    // search for search phrase parameter
    if (key === 'q') {
      searchStore.searchInput = decodeURIComponent(urlValue)
    }

    // set the tab when needed
    if (key === 'tab') {
      searchStore.setSelectedTab(urlValue)
    }

    // set the tab when needed
    if (key === 'nr') {
      searchStore.setCurrentPage(parseInt(urlValue))
    }

    // Results per page
    if (key === 'results-per-page' && RESULTS_PER_PAGE_NUMBERS.includes(parseInt(urlValue))) {
      searchStore.setresultsPerPage(parseInt(urlValue))
    }

    // Redirect
    if (key === 'redirect' && (urlValue === 'true' || urlValue === 'false')) {
      searchStore.setTypeaheadRedirect(urlValue === 'true')
    }

    // Set the tab when needed
    if (key === 'sort-by') {
      // revert url value and check if it exists in sortMapping
      const sortField = Object.entries(sortMapping).find((el) => {
        return el[0].toLowerCase() === revertSearchSEFValue(decodeURIComponent(urlValue))
      })

      if (sortField) {
        searchStore.setSortField(sortField[0])
      }
    }
  }

  /*
    Go over the values of this URL key
    revert the values back to facet values
    fetch the nice names from the cached facets object
    Set the facets as an array to support the multi select facets

    RETURNS: void
  */
  const setFacets = (key: string, value: string) => {
    if (key === 'other') {
      setOtherFacet(value)
    }

    // set facets to check for all other paramaters
    const facetKey = revertSearchFacetSEFKey(key)
    if (showAllFacetsArrayFlat.includes(facetKey)) {
      if (value.length > 0) {
        // if this key does not exists in the showAllFacetsArray
        // but it does in the showAllFacetsArray, it is hidden by default
        // We want to add it to the facets that are shown on page load because
        // we set a value
        if (!showAllFacetsArray.includes(facetKey) && !nestedFacets.includes(facetKey)) {
          showAllFacetsArray.push(key)
        }

        // Add the facet in the store
        // lookup the facet name from the cached facets
        const newFacet = value
          .split(SEARCH_URL_SEPARATOR)
          .map((value: string) => {
            // Check in cached facets and try to get the facet for this splitted value
            const cachedFacets = searchStore.facets.cachedFacets[facetKey][0]
            const cachedFacetValue = cachedFacets.data
              ? cachedFacets.data.find((facet: any) => {
                  return (
                    revertSearchSEFValue(removeSpecialCharacters(decodeHtml(facet.value))).replace(/ /g, '') ===
                    revertSearchSEFValue(removeSpecialCharacters(value)).replace(/ /g, '')
                  )
                })
              : null

            // return the found value in the new array
            return cachedFacetValue?.value ?? ''
          })
          // Filter out empty array items
          .filter((el) => el && el.length)

        // If we have a value, add the facet
        if (newFacet.length > 0) addFacet(facetKey, newFacet)
      }
    }
  }

  /*
    Set the special other facet. This requires extra lookup to get the nice facet name

    RETURNS: void
  */

  const setOtherFacet = (value: string) => {
    value.split(SEARCH_URL_SEPARATOR).forEach((facetName: string) => {
      // Find facet object for other
      const otherIndex = facetObjects.findIndex((facet) => facet.facetName === NESTED_FACET_NAMES.other.key)

      // Find the facet object in the nested facets
      const otherFacets = facetObjects[otherIndex].children?.find((facet) => {
        const compareFacetPrettyName = facet.facetPrettyName
          ? removeSpecialCharacters(facet.facetPrettyName)?.toLowerCase().replace(/ /g, '')
          : ''

        return compareFacetPrettyName === revertSearchFacetSEFKey(removeSpecialCharacters(facetName)).replace(/ /g, '')
      })

      if (otherFacets && otherFacets.facetName) addFacet(otherFacets.facetName, 'true')
    })
  }

  /*
    Check for the searchinput and check if we need to pr-select facets based on the search query

    RETURNS: void
  */
  const setSearchPhraseRedirect = () => {
    // check if we need to add a facet based on the keyword/phrase redirect array
    // Check if we need to redirect a user based on the search phrase
    if (Object.keys(SEARCH_REDIRECT_PHRASES).includes(searchStore.searchInput.toLowerCase())) {
      const foundRedirect = Object.entries(
        SEARCH_REDIRECT_PHRASES[searchStore.searchInput.toLowerCase() as keyof typeof SEARCH_REDIRECT_PHRASES]
      )
      addFacet(foundRedirect[0][0], foundRedirect[0][1])
    }
  }

  // Old getSearchUrl method to support the old url structure
  // TODO: Remove this when all is migrated
  const defineOldSearchUrl = (queryoptions: SearchOptionsInterface = {}) => {
    let url = '/browse'

    // set selected tab
    url += '?tab=' + encodeURIComponent(searchStore.tabs.selectedTab)

    // searchInput
    if (searchStore.searchInput.length > 0) {
      url += '&search=' + encodeURIComponent(searchStore.searchInput)
    }

    // facets
    const facetKeys = Object.keys(searchStore.facets.checkedFacets)
    const otherKeys: Array<string> = []
    if (facetKeys.length > 0) {
      facetKeys.forEach((key: any) => {
        const facetNameKeys = Object.keys(searchStore.facets.checkedFacets[key])
        if (nestedFacets.includes(facetNameKeys[0])) {
          const otherSlug = facetNameKeys[0].toLowerCase()
          if (otherSlug.length > 0) {
            otherKeys.push(otherSlug)
          }
        } else {
          const joinedValue = searchStore.facets.checkedFacets[key][facetNameKeys[0]].join('|')
          url += '&' + encodeURIComponent(facetNameKeys[0]) + '=' + encodeURIComponent(joinedValue)
        }
      })

      // Append possible other facet
      if (otherKeys.length > 0) {
        url += '&other=' + otherKeys.join('|')
      }
    }

    // pagination
    if (queryoptions.nr !== undefined) {
      url += '&nr=' + queryoptions.nr
    }

    // redirect, first check if we defined it
    if (queryoptions.redirect !== undefined) {
      url += '&redirect=' + queryoptions.redirect
    }
    // then check if it is already present in the URL
    else if (process.client) {
      const redirectParam = new URL(window.location.href).searchParams.get('redirect')
      if (redirectParam !== null) {
        if (redirectParam === 'false') {
          url += '&redirect=false'
        }
      }
    }

    // sortby, only add if it different then default
    if (searchStore.page.sortField !== 'Relevance') {
      if (queryoptions.sortby !== undefined) {
        url += '&sortby=' + encodeURIComponent(queryoptions.sortby)
      } else {
        url += '&sortby=' + encodeURIComponent(searchStore.page.sortField)
      }
    }

    // results per page
    if (searchStore.page.resultsPerPage !== 30) {
      url += '&resultsPerPage=' + encodeURIComponent(searchStore.page.resultsPerPage)
    }

    // Disable scroll to top
    if (queryoptions.noscroll !== undefined) {
      url += '&noscroll=1'
    }

    if (queryoptions.queryStyle) {
      url += '&qs=' + queryoptions.queryStyle
    }

    return url
  }

  /*
    TODO: Remove this when all is migrated

    Get the current route from the browse page
    extract all query parameters
    if inserted, change them in the store
    after this check, perform a search

    Needs to be async because we use await on browse page. It needs to return a promise
    RETURNS: void
  */
  const setOldUrlParameters = (path: Record<string, string | (string | null)[]>) => {
    // skip if this is empty
    if (path === undefined || path == null || Object.keys(path).length === 0) {
      return
    }
    // if several path and duplicate paths with same property
    if (path.isArray && path[0] === path[1]) {
      return path[0]
    }
    // get all path keys
    const pathkeys = Object.keys(path)

    // Back to default
    setDefaultSearchSettings()

    // go over them and convert them
    if (pathkeys.length > 0) {
      // support legacy paramter structure
      if (path.Ntt !== undefined && path.Ntt !== '') {
        searchStore.searchInput = decodeURIComponent(path.Ntt.toString())
      }
      // support new parameter structure
      pathkeys.forEach((key) => {
        const value = path[key]

        // If we have no value for this key, continue with the next loop
        if (!value) {
          return
        }

        const facetValue: string | null = Array.isArray(value) ? value[0] : value

        // If we have no value for this key, continue with the next loop
        if (!facetValue) {
          return
        }

        // search for search phrase parameter
        if (key === 'search') {
          searchStore.searchInput = facetValue
        }
        // set the tab when needed
        else if (key === 'tab') {
          searchStore.setSelectedTab(facetValue)
        }
        // set the tab when needed
        else if (key === 'nr') {
          searchStore.setCurrentPage(parseInt(facetValue))
        } else if (key === 'resultsPerPage') {
          if (RESULTS_PER_PAGE_NUMBERS.includes(parseInt(facetValue))) {
            searchStore.setresultsPerPage(parseInt(facetValue))
          }
        } else if (key === 'redirect') {
          if (facetValue === 'true' || facetValue === 'false') {
            searchStore.setTypeaheadRedirect(facetValue === 'true')
          }
        }
        // set the tab when needed
        else if (key === 'sortby') {
          // a test if this value won't break the search
          if (Object.keys(sortMapping).includes(facetValue)) {
            searchStore.setSortField(facetValue)
          }
        } else if (key === 'other') {
          facetValue.split('|').forEach((facetName: string) => {
            const otherSlug = facetName.toLowerCase()
            if (otherSlug.length > 0) addFacet(otherSlug, 'true')
          })
        }
        // set facets to check for all other paramaters
        else if (showAllFacetsArrayFlat.includes(key)) {
          if (facetValue.length > 0) {
            // if this key does not exists in the showAllFacetsArray
            // but it does in the showAllFacetsArray, it is hidden by default
            // We want to add it to the facets that are shown on page load because
            // we set a value
            if (!showAllFacetsArray.includes(key) && !nestedFacets.includes(key)) {
              showAllFacetsArray.push(key)
            }

            // Add the facet in the store
            addFacet(key, facetValue.split('|'))
          }
        }

        // check if we need to add a facet based on the keyword/phrase redirect array
        // Check if we need to redirect a user based on the search phrase
        if (Object.keys(SEARCH_REDIRECT_PHRASES).includes(searchStore.searchInput.toLowerCase())) {
          const foundRedirect = Object.entries(
            SEARCH_REDIRECT_PHRASES[searchStore.searchInput.toLowerCase() as keyof typeof SEARCH_REDIRECT_PHRASES]
          )
          addFacet(foundRedirect[0][0], foundRedirect[0][1])
        }
      })
    }
  }

  /*
      Method to get the search url, based on every search item in the store
      This method handles the feature flag for the new search url structure
      
      RETURNS: string
    */
  const getSearchUrl = (queryoptions: SearchOptionsInterface = {}) => {
    // TODO: Remove this when all is migrated
    // provide old url structure if flag is false
    return !newSearchUrlStructure.value ? defineOldSearchUrl(queryoptions) : defineSearchUrl(queryoptions)
  }

  /*
    Handle the right URL handling based on the feature flag

    Needs to be async because we use await on browse page. It needs to return a promise
    RETURNS: void
  */
  const setSearchUrl = (path: Record<string, string | (string | null)[]>) => {
    return !newSearchUrlStructure.value ? setOldUrlParameters(path) : setUrlParameters(path)
  }

  return {
    getSearchUrl,
    setSearchUrl,
  }
}
