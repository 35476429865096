











































// Composition
import { defineComponent, computed, ref, onUpdated } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

// Composables
import useSearch from '@/composables/search/useSearch'

// Types
import { SuggestionInterface } from '@/types/search/searchTypes'

// Common
import { getSearchHistoryObject } from '@/common/utils/searchHistory'
import { SEARCH_TYPES } from '@/common/static/search'

// Store
import { useSearchStore } from '@/store/search'

// Componenents
import HistorySuggestion from '@/components/global/search/SuggestionHistory.vue'
import SearchtypeSuggestion from '@/components/global/search/SuggestionSearchtype.vue'
import CategoriesSuggestion from '@/components/global/search/SuggestionCategory.vue'
import QuerySuggestions from '@/components/global/search/QuerySuggestions.vue'
import HeaderSuggestion from '@/components/global/search/SuggestionHeader.vue'

export default defineComponent({
  components: {
    HeaderSuggestion,
    HistorySuggestion,
    SearchtypeSuggestion,
    QuerySuggestions,
    CategoriesSuggestion,
  },

  setup() {
    const { searchInput, typeahead } = storeToRefs(useSearchStore())
    const { prettyTypeaheadName, loadingSuggests } = useSearch()
    const suggestionsRef = ref<HTMLElement | null>(null)

    // vars
    const storedSearchPhrases = computed(() => {
      if (process.client) {
        return getSearchHistoryObject()
      }

      return []
    })

    const typeaheadOrder = [SEARCH_TYPES.HISTORY, SEARCH_TYPES.PRODUCT, SEARCH_TYPES.PRODUCT, SEARCH_TYPES.RESOURCE]

    // methods
    const suggestionHtml = (suggestion: SuggestionInterface) => {
      if (suggestion._highlightResult !== undefined) {
        return suggestion._highlightResult.fields.name.value
      } else {
        return suggestion.name
      }
    }

    const setSuggestionHeight = () => {
      if (!process.client) {
        return
      }

      if (suggestionsRef.value == null) {
        return
      }

      // get topOffset of element
      const topOffset = suggestionsRef.value?.getBoundingClientRect().top

      // set the style attribute
      // substract min 10 to show the box ends somewhere
      const newHeight = window.innerHeight - 10 - topOffset

      // If el is defined, set style
      if (suggestionsRef.value !== null && suggestionsRef.value.style !== undefined) {
        if (suggestionsRef.value?.clientHeight > 200) {
          suggestionsRef.value.style.height = newHeight + 'px'
        } else {
          suggestionsRef.value.style.height = ''
        }
      }
    }

    // calculate the height of the suggestion box
    // since the header is fixed, we can not use 100vh - topoffset
    // This is not possible in CSS so we set the height style in JS
    if (process.client) {
      // add evenListener
      window.addEventListener('resize', setSuggestionHeight)
    }

    const scrollSelectedChange = () => {
      if (!process.client) {
        return
      }

      if (suggestionsRef.value == null) {
        return
      }

      const selectedItemIndex = Object.keys(suggestionsRef.value.children).filter((prop: any) => {
        return suggestionsRef.value?.children[prop].id === 'selected'
      })

      const selectedChild = suggestionsRef.value.children[parseInt(selectedItemIndex[0])] as HTMLElement
      if (selectedChild !== undefined) {
        suggestionsRef.value.scrollTo({
          top: selectedChild.offsetTop - 100,
          behavior: 'smooth',
        })
      }
    }

    // After an update of this component, check height again
    onUpdated(() => {
      // set height
      setSuggestionHeight()

      // check if selected item if visible or not
      scrollSelectedChange()
    })

    // Name for dynamic component
    const getComponentName = (type: string) => {
      switch (type) {
        case SEARCH_TYPES.PRODUCT:
          return 'SearchtypeSuggestion'
        case SEARCH_TYPES.HISTORY:
          return 'HistorySuggestion'
        case SEARCH_TYPES.RESOURCE:
          return 'SearchtypeSuggestion'
        case SEARCH_TYPES.CATEGORIES:
          return 'CategoriesSuggestion'
        case SEARCH_TYPES.SUGGESTIONS:
          return 'QuerySuggestions'
      }
    }

    return {
      SEARCH_TYPES,
      loadingSuggests,
      suggestionHtml,
      prettyTypeaheadName,
      storedSearchPhrases,
      searchInput,
      typeaheadOrder,
      typeahead,
      suggestionsRef,
      getComponentName,
    }
  },
})
