






// Composition
import { defineComponent, useRouter } from '@nuxtjs/composition-api'

// Composables
import useSearch from '@/composables/search/useSearch'
import useSearchUrl from '@/composables/search/useSearchUrl'
import { clearSuggestSelected } from '@/composables/search/useSuggestions'
import useSearchAnalytics from '@/composables/analytics/useSearchAnalytics'
import useFacets from '@/composables/search/useFacets'

// Common
import { decodeHTMLEntities } from '@/common/utils/characters'
import { SEARCH_TYPES } from '@/common/static/search'
import { setSearchHistoryObject } from '@/common/utils/searchHistory'
import { QueryStyle } from '~/types/search/searchTypes'
import { useSearchStore } from '~/store'

export default defineComponent({
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
    searchInput: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { addFacet } = useFacets()
    const { storedSearchInput, setDefaultSearchSettings } = useSearch()
    const { getSearchUrl } = useSearchUrl()
    const router = useRouter()
    const searchStore = useSearchStore()

    const { trackTypeaheadSuggestionClick } = useSearchAnalytics()

    const searchTypeaheadFacet = (name: string, value: string) => {
      // Clear all search settings
      setDefaultSearchSettings(true)

      // change router
      clearSuggestSelected()

      // Add new facet
      addFacet(name, value)

      // send GTM event, only for ga4
      trackTypeaheadSuggestionClick(value, SEARCH_TYPES.CATEGORIES)

      // Store searched term
      storedSearchInput.value = props.searchInput

      // Save in localStorage
      setSearchHistoryObject(props.searchInput)

      searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)

      // new search
      router.push(getSearchUrl({ redirect: false }))
    }

    return {
      decodeHTMLEntities,
      searchTypeaheadFacet,
    }
  },
})
