










// Composition
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'

// Common
import { decodeHTMLEntities } from '@/common/utils/characters'
import { SEARCH_TYPES } from '@/common/static/search'
import { setSearchHistoryObject } from '@/common/utils/searchHistory'

// Composables
import { clearSuggestSelected } from '@/composables/search/useSuggestions'
import useSearchAnalytics from '@/composables/analytics/useSearchAnalytics'
import useSearch from '@/composables/search/useSearch'

// Types
import { QueryStyle, SuggestionlistObjectInterface } from '@/types/search/searchTypes'
import { useSearchStore } from '~/store'

export default defineComponent({
  props: {
    suggestion: {
      type: Object as () => SuggestionlistObjectInterface,
      required: true,
    },
    searchInput: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { trackTypeaheadSuggestionClick, trackElasticsearchClickEvent, trackSiteSearch } = useSearchAnalytics()
    const searchStore = useSearchStore()
    const route = useRoute()
    const { getSearchResultUrl } = useSearch()

    // show name in nice format and add id for product
    const showName = (suggestion: any) => {
      if (suggestion.type === SEARCH_TYPES.PRODUCT) {
        return suggestion.title + ' #' + suggestion.id
      } else {
        return suggestion.title
      }
    }

    const isPLPSuggestion = computed(() => {
      const url = props.suggestion.fields?.url?.raw ?? props.suggestion.fields?.clickurl?.raw
      return url?.includes('browse')
    })

    const clickItemtypeSuggestion = (suggestion: SuggestionlistObjectInterface) => {
      // clear suggestions
      clearSuggestSelected()

      if (!isPLPSuggestion.value) {
        trackSiteSearch({
          index_type: suggestion.type === SEARCH_TYPES.PRODUCT ? 'products' : suggestion.type,
          search_origin: route.value.path,
          query_typed: searchStore.typedSearchQuery,
          query_executed: suggestion.title,
          results_quantity: undefined,
          results_page_quantity: undefined,
          filters: undefined,
          response_time: undefined,
          sort_by: undefined,
          query_style: QueryStyle.TYPEAHEAD_REDIRECT,
        })

        searchStore.setQueryStyle(QueryStyle.NAVIGATION)
      } else {
        searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)
      }

      // send GTM event
      trackTypeaheadSuggestionClick(suggestion.title, suggestion.type)

      // Store in localStorage
      setSearchHistoryObject(props.searchInput)

      // Send Elastic event
      if (suggestion.type !== SEARCH_TYPES.CATEGORIES && suggestion.id !== undefined) {
        trackElasticsearchClickEvent(suggestion?.id)
      }
    }

    return {
      showName,
      clickItemtypeSuggestion,
      getSearchResultUrl,
      decodeHTMLEntities,
    }
  },
})
