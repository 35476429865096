


















// Composition
import { defineComponent, useRouter } from '@nuxtjs/composition-api'

// LoDash
import unescape from 'lodash/fp/unescape'

// Store
import { useSearchStore } from '~/store/search'

// Composables
import useSearch from '@/composables/search/useSearch'
import useSearchUrl from '@/composables/search/useSearchUrl'
import { clearSuggestSelected } from '@/composables/search/useSuggestions'
import useSearchAnalytics from '@/composables/analytics/useSearchAnalytics'

// Common
import { clearSearchHistoryObject, setSearchHistoryObject } from '@/common/utils/searchHistory'
import { highlightText, stripHTMLTags } from '@/common/utils/characters'
import { SEARCH_TYPES } from '@/common/static/search'

// Components
import IconFa6SolidXmark from '~icons/fa6-solid/xmark'

// Types
import { QueryStyle, SuggestionlistObjectInterface } from '@/types/search/searchTypes'

export default defineComponent({
  components: {
    IconFa6SolidXmark,
  },

  props: {
    suggestion: {
      type: Object as () => SuggestionlistObjectInterface,
      required: true,
    },
    searchInput: {
      type: String,
      required: true,
    },
  },

  setup() {
    const searchStore = useSearchStore()
    const { storedSearchInput } = useSearch()
    const { getSearchUrl } = useSearchUrl()
    const router = useRouter()
    const { trackTypeaheadSuggestionClick } = useSearchAnalytics()

    const deleteHistoryItem = (value: string) => {
      // remove from localStorage
      clearSearchHistoryObject(value)

      // delete from store as well
      searchStore.deleteSuggest(SEARCH_TYPES.HISTORY, value)
    }

    const clickOnSearchHistoryItem = (suggestion: SuggestionlistObjectInterface) => {
      // clear selected
      clearSuggestSelected()

      // clear all facets
      searchStore.deleteCheckedFacets()

      // Set selected tab to product
      searchStore.setSelectedTab(SEARCH_TYPES.PRODUCT)

      // Set search input
      const strippedTitle = stripHTMLTags(suggestion.title)
      searchStore.setSearchInput(encodeURIComponent(strippedTitle))

      // Store again in history to re-order
      setSearchHistoryObject(strippedTitle)

      // Store search input
      storedSearchInput.value = strippedTitle

      searchStore.setQueryStyle(!searchStore.typedSearchQuery ? QueryStyle.HISTORY : QueryStyle.TYPEAHEAD)

      // perform a search
      router.push(getSearchUrl({ redirect: false }))

      // send GTM event, only for ga4
      trackTypeaheadSuggestionClick(strippedTitle, SEARCH_TYPES.HISTORY)
    }

    return {
      deleteHistoryItem,
      unescape,
      highlightText,
      clickOnSearchHistoryItem,
    }
  },
})
