import escape from 'lodash/fp/escape'
import unescape from 'lodash/fp/unescape'
import isEmpty from 'lodash/fp/isEmpty'
import { LocalStorageObjectInterface } from '@/types/common/utils/localstorageTypes'
import { sanitizeString } from '@/common/utils/characters'

const SEARCH_HISTORY_NAME = 'elasticsearchSearchphrases'
/*
  Set search history search phrases
  Order them based on the timestamp
*/
const setSearchHistoryObject = (value: string) => {
  if (isEmpty(value)) {
    return
  }

  // check if there's already an object with this name
  const checkStoredObject = getSearchHistoryObject(SEARCH_HISTORY_NAME) as LocalStorageObjectInterface
  if (checkStoredObject !== null) {
    // trim and escape value
    value = escape(sanitizeString(value).trim())

    // If empty, do not save anyting
    if (isEmpty(value)) {
      return
    }

    // add prop or change date to now
    checkStoredObject[value] = Date.now()

    // sort properties by value (date) and return object
    const checkStoredObjectSorted = Object.keys(checkStoredObject)
      .sort((key1, key2) => checkStoredObject[key1] - checkStoredObject[key2])
      .reduce(
        (obj, key) => ({
          [key]: checkStoredObject[key],
          ...obj,
        }),
        {}
      )

    // check if the number of 250 saved searches is extended. If so, delete the last one
    if (Object.keys(checkStoredObjectSorted).length > 250) {
      const lastKey = Object.keys(checkStoredObjectSorted).pop()
      delete checkStoredObjectSorted[lastKey as keyof typeof checkStoredObjectSorted]
    }

    // set the new object
    localStorage.setItem(SEARCH_HISTORY_NAME, JSON.stringify(checkStoredObjectSorted))
  } else {
    localStorage.setItem(SEARCH_HISTORY_NAME, JSON.stringify({ [value]: Date.now() }))
  }
}

/*
  Retrieve the search history items
*/
const getSearchHistoryObject = (options: any = {}) => {
  if (localStorage && localStorage === undefined) {
    return
  }

  const newObject = localStorage.getItem(SEARCH_HISTORY_NAME)
  if (newObject !== null) {
    const parseObject = JSON.parse(newObject) as Record<string, number>

    // Sanitize object
    const sanitizedObject = Object.fromEntries(
      Object.entries(parseObject).map(([key, value]) => [sanitizeString(key), value])
    )

    // Return capped object
    if (options.cap !== undefined) {
      const cappedObject = Object.fromEntries(Object.entries(sanitizedObject).slice(0, options.cap))
      return cappedObject
    }

    // return
    return sanitizedObject
  }
  return null
}

/*
  Delete a search history
*/
const clearSearchHistoryObject = (key: string = '') => {
  const storageObject = JSON.parse(localStorage[SEARCH_HISTORY_NAME])

  // delete key
  if (key.length > 0) {
    const trimmedKey = escape(key.trim())
    delete storageObject[trimmedKey]
    localStorage.setItem(SEARCH_HISTORY_NAME, JSON.stringify(storageObject))
  } else {
    localStorage.setItem(SEARCH_HISTORY_NAME, JSON.stringify({}))
  }
}

/*
  Filter the search history items based on the search input
*/
const filterSearchHistory = (value: string) => {
  const checkStoredObject = getSearchHistoryObject(SEARCH_HISTORY_NAME) as LocalStorageObjectInterface

  if (checkStoredObject === undefined || checkStoredObject === null) {
    return
  }

  const objectKeys = Object.keys(checkStoredObject)
  if (objectKeys.length > 0) {
    const filteredObject = objectKeys
      .filter((key) => unescape(key.toLowerCase()).includes(value.toLowerCase()))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: checkStoredObject[key] })
      }, {})

    if (Object.keys(filteredObject).length > 0) {
      return Object.fromEntries(Object.entries(filteredObject).slice(0, 3))
    }
  }
}

export { setSearchHistoryObject, getSearchHistoryObject, clearSearchHistoryObject, filterSearchHistory }
