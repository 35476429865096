import { Context } from '@nuxt/types'

const SEMICOLON = ';'
// .. Fire redirect with correct redirect code
export default function (context: Context) {
  let path = context.route.path
  const fullPath = decodeURIComponent(context.route.fullPath)
  const hasScriptCharacters = fullPath.match(/[<>]/g)

  // If we find script characters, redirect to the same page without url parameters
  if (hasScriptCharacters && hasScriptCharacters.length > 0) {
    context.redirect(path)
  }

  if (path.includes(SEMICOLON)) {
    path = path.split(SEMICOLON)[0]
    context.redirect(301, path)
  }
}
