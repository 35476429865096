// Elasticsearch specific fields
import { ElasticContentFieldTypeInterface, ElasticFieldsInterface } from '@/types/search/elasticTypes'
import { ErrorInterface } from '@/types/errors/errorsTypes'

// EXPORTS

export enum QueryStyle {
  HISTORY = 'history',
  TYPEAHEAD = 'typeahead',
  TYPEAHEAD_REDIRECT = 'typeahead_redirect',
  KEYWORD = 'keyword',
  KEYWORD_REDIRECT = 'keyword_redirect',
  NAVIGATION = 'navigation',
  FILTER_ENGAGED = 'filter_engaged',
  UI = 'ui',
}

export interface SelectedFacetInterface {
  facetkey?: string
  facetname: string
  count: string
}

export interface StaticFacetsInterface {
  facetName: string
  facetPrettyName?: string
  popularFacet?: boolean
  parentValue?: string | number
  children?: Array<StaticFacetsInterface>
  multiSelect?: boolean
}

export interface FacetInterface {
  count: number
  value: string
  children?: StaticFacetsInterface
}

export interface FacetObjectInterface {
  type?: string
  data?: Array<FacetInterface>
}

export interface FacetObjectsInterface {
  [key: string]: Array<FacetObjectInterface>
}

export interface FacetNestedAndHiddenSelectedInterface {
  nested?: boolean
  hidden?: boolean
}

export interface SuggestObjectInterface {
  name: string
  type: string
  data: any
}

export interface SuggestsObjectInterface {
  [key: string]: SuggestObjectInterface
}

export interface ResultsObjectInterface {
  meta?: {
    alerts?: Array<string>
    engine?: {
      name?: string
      type?: string
    }
    page?: {
      current?: number
      size?: number
      total_pages?: number
      total_results?: number
    }
    precision?: number
    request_id?: string
    warnings?: Array<string>
  }
}

export interface SearchResultsObjectInterface extends ResultsObjectInterface {
  facets?: FacetObjectsInterface
  results?: Array<ElasticFieldsInterface>
}

export interface ApplicationKeyInterface {
  chiclet: string
  active: boolean
  clickable: boolean
}

export interface SuggestionlistObjectInterface {
  selected: boolean
  header: boolean
  type: string
  title: string
  total?: number
  id?: string
  url?: string
  fields?: ElasticFieldsInterface
}

export interface CheckedFacetsInterface {
  [key: string]: any
}

export interface AppliedFacetInterface {
  key: string
  value: string
}

export interface SearchSuggestionsInterface {
  results: {
    documents: Array<{ suggestion: string }>
  }
  meta: {
    request_id: string
  }
}

export interface SearchStoreInterface {
  searchInput: string
  results: {
    [key: string]: SearchResultsObjectInterface
  }
  page: {
    resultsPerPage: number
    currentPage: number
    sortField: string
    responseTime: number
  }
  ui: {
    showLoader: boolean
    showMobileFacets: boolean
    showAllFacets: boolean
    showSearching: boolean
  }
  typeahead: {
    redirect: boolean
    showSuggests: boolean
    showHistoryOnly: boolean
    suggestionList: Array<SuggestionlistObjectInterface>
  }
  facets: {
    checkedFacets: Array<CheckedFacetsInterface>
    cachedFacets: Record<string, FacetObjectInterface[]>
    clickedMultiselectFacet: {
      facetname: string
      facetdata: Array<FacetObjectInterface>
    }
  }
  addToBasketProduct: ElasticFieldsInterface
  queryStyle: QueryStyle | null
  tabs: {
    selectedTab: string
    tabCount: {
      product?: Number
      resources?: Number
    }
  }
  searchSuggestions?: SearchSuggestionsInterface
  searchErrors: ErrorInterface[]
  typedSearchQuery: string
  storedSearchInput: string
  storedTypeaheadSearchInput: string
  infoPanelsOpen: boolean
  infoPanelOpenIDs: string[]
}

export interface FacetsObjectInterface {
  [key: string]: Array<Object>
}

export interface TabCounInterface {
  product: Number | undefined
  resources: Number | undefined
}

export interface FieldsearchInterface {
  field?: string
  val?: string
}

export interface SearchOptionsInterface {
  fieldsearch?: FieldsearchInterface
  nr?: Number
  facets?: Array<string>
  searchProductNumber?: string
  redirect?: boolean
  noscroll?: boolean
  tab?: string
  sortby?: string
  queryStyle?: QueryStyle
}

export interface SuggestionAreaInterface {
  name: string
  newField?: string
  type?: string
  data?: any
}

export interface SuggestionDataInterface {
  name: ElasticContentFieldTypeInterface
  [key: string]: ElasticContentFieldTypeInterface
}

export interface SuggestionInterface {
  term: string
  name: ElasticContentFieldTypeInterface
  _highlightResult: {
    fields: {
      name: {
        value: string
      }
    }
  }
}
