











































// Composition
import { defineComponent, useRouter, computed, useContext, useRoute } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'

// Tailwind
import tailwindConfig from '@/tailwind.config.js'

// Store
import { useSearchStore } from '~/store/search'

// Composable
import useSearch from '@/composables/search/useSearch'
import useSearchUrl from '@/composables/search/useSearchUrl'
import { selectedIndex, clearSuggestSelected } from '@/composables/search/useSuggestions'
import { toggleShowBasket } from '@/composables/checkout/useBasket'
import useSearchAnalytics from '@/composables/analytics/useSearchAnalytics'
import useFacets from '@/composables/search/useFacets'

// Types
import { DomEventInterface } from '@/types/dom/domTypes'

// Common
import { setSearchHistoryObject } from '@/common/utils/searchHistory'
import { SEARCH_TYPES } from '@/common/static/search'
import { decodeHTMLEntities } from '@/common/utils/characters'

// Components
import IconFa6SolidXmark from '~icons/fa6-solid/xmark'

import { noneFilterArray } from '@/common/static/facets'
import { QueryStyle } from '~/types/search/searchTypes'

export default defineComponent({
  components: {
    IconFa6SolidXmark,
  },

  props: {
    showTypeahead: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const ctx = useContext()
    const router = useRouter()
    const route = useRoute()
    const { adjustCheckedFacets } = useFacets()
    const searchStore = useSearchStore()
    const { searchInput, typeahead } = storeToRefs(searchStore)
    const {
      storedSearchInput,
      storedTypeaheadSearchInput,
      setDefaultSearchSettings,
      typeaheadGetAll,
      getHistorySuggests,
      getRedirectProductNumber,
      performSearch,
    } = useSearch()
    const { getSearchUrl, setSearchUrl } = useSearchUrl()
    const { trackTypeaheadSuggestionClick, trackSiteSearch } = useSearchAnalytics()
    const specialCharactersArray = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Enter', 'Escape']

    // local vars
    let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {})
    const debounceTime: number = 500

    // On page load, set this value to match the searchInput
    storedTypeaheadSearchInput.value = searchStore.searchInput

    const search = async () => {
      // close basket sidebar if needed
      toggleShowBasket(false)

      // reset suggests
      setDefaultSearchSettings(true)

      // store search phrase in local storage
      if (process.client && searchInput.value.length > 0) {
        setSearchHistoryObject(searchInput.value)
      }

      // store searchInput in a non-reactive var
      storedSearchInput.value = searchInput.value

      // go to browse page
      searchStore.hideSuggests()

      // User type product number into search then redirect to pdp directly (Not through /browse page)
      if (getRedirectProductNumber()) {
        const currentUrl = getSearchUrl({ redirect: true })
        const newUrlParam = Object.fromEntries(new URL(currentUrl, window.location.origin).searchParams.entries())
        setSearchUrl(newUrlParam)
        const response = await performSearch()

        if (
          response?.directproductnumber !== undefined &&
          response.categories?.raw &&
          Array.isArray(response.categories.raw) &&
          !noneFilterArray[0].categories.includes(response.categories.raw[0])
        ) {
          trackSiteSearch({
            index_type: 'products',
            search_origin: route.value.path,
            query_typed: searchStore.typedSearchQuery,
            query_executed: searchStore.searchInput,
            results_quantity: undefined,
            results_page_quantity: undefined,
            filters: undefined,
            response_time: undefined,
            sort_by: undefined,
            query_style: QueryStyle.KEYWORD_REDIRECT,
          })

          searchStore.setQueryStyle(QueryStyle.NAVIGATION)

          if (location) {
            location.href = response.clickurl.raw
          } else {
            ctx.redirect(response.clickurl.raw)
          }
          return
        }
      }

      searchStore.setQueryStyle(QueryStyle.KEYWORD)

      // perform a new search
      router.push(getSearchUrl({ redirect: true }))
    }

    // Special character events
    const onArrowLeft = (event: any) => {
      if (selectedIndex.value !== null) {
        event.preventDefault()
        hitArrowUpKey()
      }
    }

    const onArrowRight = (event: any) => {
      if (selectedIndex.value !== null) {
        event.preventDefault()
        hitArrowDownKey()
      }
    }
    const onArrowDown = () => hitArrowDownKey()
    const onArrowUp = () => hitArrowUpKey()
    const onEnter = (e: Event) => hitEnterKey(e)
    const onEscape = () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
      searchStore.hideSuggests()
    }

    const searchAndSuggest = (e: DomEventInterface) => {
      // Do nothing for special characters
      if (specialCharactersArray.includes(e.key)) {
        return
      }

      // If it passes all checks, we perform a new search. Set the selectedIndex to 0 to start again if needed
      selectedIndex.value = null
      changeShowSuggests()

      // debounce function
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      debounceTimeout = setTimeout(() => {
        // match the searchinput
        storedTypeaheadSearchInput.value = searchStore.searchInput

        if (searchInput.value.length > 0) {
          typeaheadGetAll()
        } else {
          searchStore.resetSuggests()
          searchStore.setSuggests(getHistorySuggests())
          searchStore.typeahead.showHistoryOnly = true
        }
      }, debounceTime)
    }

    const changeShowSuggests = () => {
      typeahead.value.showSuggests = props.showTypeahead
    }

    // function that is executed when a user hits the enter key
    const hitEnterKey = (e: Event) => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      const target = e.target as HTMLInputElement
      target.blur()

      // check if the selectedIndex is higher then 0.
      // if so, perform a search as it was a click on a suggestion
      if (selectedIndex.value !== null && selectedIndex.value > 0) {
        // perform search based on the selectedIndex value
        const selectedObject = typeahead.value.suggestionList[selectedIndex.value]

        // if this is a header, perform a header action
        if (selectedObject.header !== undefined) {
          // For product and resource
          if (selectedObject.type === SEARCH_TYPES.PRODUCT || selectedObject.type === SEARCH_TYPES.RESOURCE) {
            // clear selected index
            clearSuggestSelected()

            // hide suggests box
            searchStore.hideSuggests()

            // Delete all facets
            if (selectedObject.type === SEARCH_TYPES.RESOURCE) {
              searchStore.deleteCheckedFacets()
            }

            // Set tab
            searchStore.setSelectedTab(selectedObject.type)

            searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)

            // push to new URL
            router.push(getSearchUrl())

            // Store the search term
            storedSearchInput.value = searchStore.searchInput

            // Hide the typeahead box
            searchStore.hideSuggests()

            return
          }

          // for categories
          if (selectedObject.type === SEARCH_TYPES.CATEGORIES) {
            // Set selected tab
            searchStore.setSelectedTab(SEARCH_TYPES.PRODUCT)

            // Delete all facets
            searchStore.deleteCheckedFacets()

            searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)

            // Change URL
            router.push(getSearchUrl({ redirect: false }))

            // Store the search term
            storedSearchInput.value = searchStore.searchInput

            // Hide the typeahead box
            searchStore.hideSuggests()

            return
          }

          // if no history, change route
          if (selectedObject.type !== SEARCH_TYPES.HISTORY) {
            searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)

            // Perform a new search
            router.push(getSearchUrl({ redirect: false }))

            // Store the search term
            storedSearchInput.value = searchStore.searchInput

            // Hide the typeahead box
            searchStore.hideSuggests()

            return
          }
        }

        // if it is a redirect a PDP
        if (selectedObject.type === SEARCH_TYPES.PRODUCT || selectedObject.type === SEARCH_TYPES.RESOURCE) {
          // clear selected in store and set local index to null
          clearSuggestSelected()

          trackSiteSearch({
            index_type: selectedObject.type === SEARCH_TYPES.PRODUCT ? 'products' : 'resources',
            search_origin: route.value.path,
            query_typed: searchStore.typedSearchQuery,
            query_executed: selectedObject.title,
            results_quantity: undefined,
            results_page_quantity: undefined,
            filters: undefined,
            response_time: undefined,
            sort_by: undefined,
            query_style: QueryStyle.TYPEAHEAD_REDIRECT,
          })

          searchStore.setQueryStyle(QueryStyle.NAVIGATION)

          // hide suggestions and go to PDP
          if (selectedObject.url !== undefined && window !== undefined) {
            window.location.replace(selectedObject.url)
          }
          return
        }

        // if it is a category
        if (selectedObject.type === SEARCH_TYPES.CATEGORIES) {
          // clear suggestions
          clearSuggestSelected()

          // delete all checked facets
          searchStore.deleteCheckedFacets()

          // Set selected tab
          searchStore.setSelectedTab(SEARCH_TYPES.PRODUCT)

          // send GTM event, only for ga4
          trackTypeaheadSuggestionClick(selectedObject.title, SEARCH_TYPES.CATEGORIES)

          // Change facet
          adjustCheckedFacets(SEARCH_TYPES.CATEGORIES, selectedObject.title)

          searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)

          // hide suggestions and go to page browse
          router.push(getSearchUrl({ redirect: false }))

          // Store the search term
          storedSearchInput.value = searchStore.searchInput

          // Hide the typeahead box
          searchStore.hideSuggests()

          return
        }

        // if is history
        if (selectedObject.type === SEARCH_TYPES.HISTORY) {
          // Clear suggestion selection
          clearSuggestSelected()

          // Set search input
          searchStore.setSearchInput(encodeURIComponent(selectedObject.title))

          searchStore.setQueryStyle(!searchStore.typedSearchQuery ? QueryStyle.HISTORY : QueryStyle.TYPEAHEAD)

          // Perform a new search
          router.push(getSearchUrl({ redirect: false }))

          // Store the search term
          storedSearchInput.value = selectedObject.title

          // Hide the typeahead box
          searchStore.hideSuggests()

          return
        }

        // if is a suggestion
        if (selectedObject.type === SEARCH_TYPES.SUGGESTIONS) {
          // clear selected index
          clearSuggestSelected()

          // change search input
          searchStore.setSearchInput(encodeURIComponent(selectedObject.title))

          searchStore.setQueryStyle(QueryStyle.TYPEAHEAD)

          // perform a new search
          router.push(getSearchUrl({ redirect: false }))

          // hide the typeahead box
          searchStore.hideSuggests()

          // Store the search term
          storedSearchInput.value = selectedObject.title

          // Store in localStorage
          setSearchHistoryObject(selectedObject.title)
          return
        }
      }

      // If none of these keys are hit, perform a regular search
      // perform search
      search()
    }

    // function that is executed when a user hits the arrowDown key
    const hitArrowDownKey = () => {
      if (typeahead.value.suggestionList?.length === 0) {
        // hide the typeahead box
        searchStore.hideSuggests()
      } else {
        // if the suggest is not visible, show it again
        changeShowSuggests()
      }

      if (selectedIndex.value == null) {
        // store the input variable
        storedTypeaheadSearchInput.value = searchStore.searchInput

        // check if there a search header, if so, set to 1
        selectedIndex.value =
          typeahead.value.suggestionList.length > 0 && typeahead.value.suggestionList[0].type === SEARCH_TYPES.HISTORY
            ? 1
            : 0

        // Exclude headers
        if (
          typeahead.value.suggestionList.length > 0 &&
          typeahead.value.suggestionList[selectedIndex.value]?.header === undefined
        ) {
          searchStore.setSearchInput(encodeURIComponent(typeahead.value.suggestionList[selectedIndex.value]?.title))
        }

        // CHange selected
        searchStore.setSuggestSelected(null, selectedIndex.value)
      } else if (selectedIndex.value < typeahead.value.suggestionList.length - 1) {
        // Store the new index
        const newIndex = selectedIndex.value + 1

        // Set the new index in the store
        searchStore.setSuggestSelected(selectedIndex.value, newIndex)

        // Based on the new index, check if this item has type history
        if (typeahead.value.suggestionList[newIndex].type === SEARCH_TYPES.HISTORY) {
          // if yes, change the searchInput to this title
          searchStore.setSearchInput(encodeURIComponent(typeahead.value.suggestionList[newIndex].title))
        } else {
          // else, revert to the storedTypeahead value
          searchStore.setSearchInput(encodeURIComponent(storedTypeaheadSearchInput.value))
        }

        selectedIndex.value++
      }
    }

    const setInput = (e: Event) => {
      const target = e.target as HTMLInputElement
      searchStore.setTypedSearchQuery(encodeURIComponent(target.value))
      searchStore.setSearchInput(encodeURIComponent(target.value))
    }

    // function that is executed when a user hits the arrowUp key
    const hitArrowUpKey = () => {
      // determine the length number from where we need to set the selectedIndex to null
      // this is 1 if we have history items. We want to exlude the search header from this functionaluty
      // Otherwise, it's 0
      const baseNumber =
        typeahead.value.suggestionList.length > 0 && typeahead.value.suggestionList[0].type === SEARCH_TYPES.HISTORY
          ? 1
          : 0

      if (selectedIndex.value !== null && selectedIndex.value > baseNumber) {
        const newIndex = selectedIndex.value - 1

        // Based on the new index, check if this item has type history
        if (typeahead.value.suggestionList[newIndex].type === SEARCH_TYPES.HISTORY) {
          // if yes, change the searchInput to this title
          searchStore.setSearchInput(encodeURIComponent(typeahead.value.suggestionList[newIndex].title))
        }

        // Change selected
        searchStore.setSuggestSelected(selectedIndex.value, newIndex)
        selectedIndex.value--
      } else {
        selectedIndex.value = null
        searchStore.setSuggestSelected(baseNumber, selectedIndex.value)
        searchStore.setSearchInput(encodeURIComponent(storedTypeaheadSearchInput.value))
      }
    }

    const focusSearchField = () => {
      // showSuggestions
      changeShowSuggests()

      // if the searchInput is bigger then 0, get suggestions
      if (searchInput.value.length > 0) {
        // search for all typeahead stacks
        typeaheadGetAll()
      } else {
        // only load history
        searchStore.setSuggests(getHistorySuggests())
      }
    }

    const placeholderString = computed(() => {
      if (process.client) {
        // parseInt and delete the px part from the string
        if (window.innerWidth < parseInt(tailwindConfig.theme.screens.xs, 10)) {
          return 'Search'
        }

        if (window.innerWidth < parseInt(tailwindConfig.theme.screens.md, 10)) {
          return 'Search by keyword'
        }

        return 'Search by keyword or product number'
      }
    })

    return {
      search,
      setInput,
      searchAndSuggest,
      onArrowLeft,
      onArrowRight,
      onArrowDown,
      onArrowUp,
      onEnter,
      onEscape,
      searchInput,
      focusSearchField,
      typeahead,
      searchStore,
      placeholderString,
      decodeHTMLEntities,
    }
  }, // end setup
})
